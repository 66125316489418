@import 'styles/variable.scss';

.wrapper {
    width: 100%;
    height: 100%;
}

.loading {
    &Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $primaryBlue;

        width: 100vw;
        height: 50vh;
    }
}